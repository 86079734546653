import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Articles from "../components/Articles"
import "./experts.scss"

const ExpertsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      groupImage: file(relativePath: { eq: "Component 29 – 1@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kotlerImage: file(relativePath: { eq: "H_kotler_hero@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mg: file(relativePath: { eq: "H_golsmith_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pk: file(relativePath: { eq: "H_kotler_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rk: file(relativePath: { eq: "H_Ron_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rs: file(relativePath: { eq: "H_Robin_nid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jd: file(relativePath: { eq: "H_Jack_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hs: file(relativePath: { eq: "H_Simon_mid@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gt: file(relativePath: { eq: "P_GT_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vh: file(relativePath: { eq: "V_VH_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kko: file(relativePath: { eq: "K_KK_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dg: file(relativePath: { eq: "D_DG_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vn: file(relativePath: { eq: "V_VN_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rm: file(relativePath: { eq: "R_RM_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nk: file(relativePath: { eq: "N_NK_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drk: file(relativePath: { eq: "DR_DRK_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nr: file(relativePath: { eq: "N_NR_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [openContact, SetOpenContact] = React.useState(false)

  return (
    <Layout formTrigger={openContact} cb={SetOpenContact}>
      <SEO title="Experts" />
      <section className="fold1">
        <div className="container">
          <div className="leftCol col">
            <h1>Innovation, Strategy & Leadership Coaching Experts</h1>
            <p>
              Learn from greatest thought leaders, renowned visionaries,
              creative problem solvers, bestselling authors, and business
              authorities.
            </p>
            <button onClick={() => SetOpenContact(true)} className="round pink">
              Let’s Talk {">"}{" "}
            </button>
          </div>
          {/* <div className="rightCol col">
            <Img
              className="soloHero"
              fluid={data.groupImage.childImageSharp.fluid}
              alt="goldsmith"
            />
          </div> */}
        </div>
        <div className="dottedBlack hideSm"></div>
      </section>
      <section className="heroExpertsDetail">
        <div className="container">
          <div className="meta">
            <h1>Our experts</h1>
          </div>
          <div className="expertsGrid">
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.mg.childImageSharp.fluid}
                alt="Marshal Goldsmith"
              />
              <h2>
                Marshal <br /> Goldsmith
              </h2>
              <p>Leadership Thinker, Exec Coach, NYT Bestseller</p>
            </div>
            {/* <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.pk.childImageSharp.fluid}
                alt="Philip Kotler"
              />
              <h2>
                Philip <br /> Kotler
              </h2>
              <p>
                Distinguished Professor, Kellogg Graduate School of Management
              </p>
            </div> */}
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.rk.childImageSharp.fluid}
                alt="Ron Kaufman"
              />
              <h2>
                Ron <br /> Kaufman
              </h2>
              <p>
                Award-winning Customer Exp Consultant, Leadership & Motivational
                Keynote Speaker
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.hs.childImageSharp.fluid}
                alt="Hermman Simon"
              />
              <h2>
                Hermman <br /> Simon
              </h2>
              <p>
                Author & Chairman of Simon-Kucher & Partners, Strategy &
                Marketing Consultants
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.rs.childImageSharp.fluid}
                alt="Robin Sharma"
              />
              <h2>
                Robin <br /> Sharma
              </h2>
              <p>Writer and Leadership Speaker</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.gt.childImageSharp.fluid}
                alt="Gareth Tennant"
              />
              <h2>
                Gareth <br /> Tennant
              </h2>
              <p>
                Director, Decison Advantage Consulting & Sr Consultant, RJD
                Technology Ltd
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.vh.childImageSharp.fluid}
                alt="Verne Harnish"
              />
              <h2>
                Verne <br /> Harnish
              </h2>
              <p>
                World renowned Speaker, Author, and Founder of the
                Entrepreneurs’ Organization (EO)
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.kko.childImageSharp.fluid}
                alt="KAIHAN KRIPPENDORFF"
              />
              <h2>
                Kaihan <br /> Krippendorff
              </h2>
              <p>Founder-Outthinker, Speaker and Author</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.dg.childImageSharp.fluid}
                alt="DANIEL GOLEMAN"
              />
              <h2>
                Daniel <br /> Goleman
              </h2>
              <p>Author and Science Journalist</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.vn.childImageSharp.fluid}
                alt="VINEET NAYAR"
              />
              <h2>
                Vineet <br /> Nayar
              </h2>
              <p>Former CEO of HCL Technologies</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.rm.childImageSharp.fluid}
                alt="RITA MCGRATH"
              />
              <h2>
                Rita <br /> Mcgrath
              </h2>
              <p>
                Globally recognized expert on Strategy, Innovation, and Growth
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.nk.childImageSharp.fluid}
                alt="NIRMALYA KUMAR"
              />
              <h2>
                Nirmalya <br /> Kumar
              </h2>
              <p>
                Lee Kong Chian Professor of Marketing, Singapore Management
                University
              </p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.drk.childImageSharp.fluid}
                alt="DR. ROBERT KAPLAN"
              />
              <h2>
                Dr. Robert <br /> Kaplan
              </h2>
              <p>Management thinker, Baker Foundation Professor</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.jd.childImageSharp.fluid}
                alt="Jack Daly"
              />
              <h2>
                Jack <br /> Daly
              </h2>
              <p>Sales Management Expert</p>
            </div>
            <div className="heroExpert">
              <Img
                className="spot_shadow"
                fluid={data.nr.childImageSharp.fluid}
                alt="NEIL RACKHAM"
              />
              <h2>
                Neil <br /> Rackham
              </h2>
              <p>Author, Consultant and Academic</p>
            </div>
          </div>
          {/* <div className="expertListsGrid">
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.mg.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Marshal <br /> Goldsmith
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.pk.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Philip <br /> Kotler
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.pk.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Philip <br /> Kotler
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.rk.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Ron <br /> Kaufman
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.rs.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Robin <br /> Sharma
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.hs.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Hermann <br /> Simon
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="expertListItem">
              <div className="expertImg">
                <Img
                  className="spot_shadow"
                  fluid={data.jd.childImageSharp.fluid}
                  alt="Marshal Goldsmith"
                />
                <div className="previewExpertVideo">
                  <button className="videoPlaybutton"></button>
                  <span>watch preview</span>
                </div>
              </div>
              <div className="expertData">
                <div className="expertTitle">
                  <h2>
                    Jack <br /> Daly
                  </h2>
                  <button className="round pink">Request Session {">"}</button>
                </div>
                <p>
                  The leading international symposium for the executive
                  community, where global icons debate the most pressing issues
                  of the day. Where global icons debate the most pressing issues
                  of the day.
                </p>
                <div className="expertSkills">
                  <h2>Coaches on</h2>
                  <div className="skillsGrid">
                    <span className="skill">Leadership</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Strategy & Innovation</span>
                    <span className="skill">Leadership</span>
                    <span className="skill">Leadership</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <Articles /> */}
    </Layout>
  )
}

export default ExpertsPage
